/* global $ */
'use strict'

function cloneImg () {
  if ($(window).width() < 800) {
    $.each($('.articlelist article'), function (index, value) {
      var $that = $(this)
      var $teaserImage = $('.teaser-image', $that)
      var $readOn = $('.read-on', $that)
      $teaserImage
        .clone()
        .addClass('moved-image')
        .insertAfter($readOn)
    })
  }
}

$('.gallery').each(function () {
  $(this).magnificPopup({
    tClose: 'Zapri (Esc)',
    tLoading: 'Nalaganje...',
    gallery: {
      tPrev: 'Nazaj',
      tNext: 'Naprej',
      tCounter: '%curr% od %total%',
      enabled: true
    },
    delegate: 'a',
    type: 'image'
  })
})

$(document).ready(function () {
  $('.depth0 .srce').clone().insertAfter('.depth0 .front-articles')
  $('.main-content h2.st').clone().insertAfter('.keyvisual .contentHtml img, .no-key span')
  $('.navigation').prepend('<a href="#" class="select">Izberi</a>')
  $('.select').click(function (event) {
    event.preventDefault()
    $('.sf-menu').slideToggle()
  })
  $('.header .navbar-nav').append("<a href='#' class='close'></a>")
  $('.hamburger').click(function (event) {
    event.preventDefault()
    $('.header .navbar-nav').toggleClass('opened')
  })
  $('.header .navbar-nav .close').click(function (event) {
    event.preventDefault()
    console.log('test')
    $('.header .navbar-nav').removeClass('opened')
  })
  cloneImg()
})

